import React, {useContext, useState, useRef, useEffect} from 'react';
import styles from '../styles/categories.module.scss';
import { FiChevronLeft, FiChevronRight, FiPlay, FiImage } from 'react-icons/fi';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { UserContext } from '../Contexts/UserContext';

export default function Categories() {

  const {apiUrl, siteId} = useContext(UserContext);
  const navigate = useNavigate();
  const topRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [categories, setCategories] = useState([]);

  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);

      // console.log(response.data)
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  };

  useEffect(()=>{
    getAllCategoriesForSite(siteId);
  },[]);

  return (
    <div className={styles.container} style={{direction: i18n.language === 'en' ? 'ltr' : 'rtl'}} ref={topRef} >
      <div className={styles.header} onClick={() => navigate(-1)} >
        {
          i18n.language === 'en'
          ? <FiChevronLeft size={38} color='#fff' className={styles.backBtn} />
          : <FiChevronRight size={38} color='#fff' className={styles.backBtn} />
        }
        <p>{t("categories.title")}</p>
      </div>

      <div className={styles.catsWrapper} >
        {
          categories.length > 0 && categories.map((it, index)=>
          <div key={it.id} className={styles.cat} >
            <img alt="category" src={it.image} style={{height: 'auto', width: '100%', objectFit: "contain"}} />
            <Link style={{textDecoration: 'none'}} to={`/${it.id}`} >
              <button className={styles.catBtn} >
                {i18n.language === "en" ? it.en_title : it.ar_title}
              </button>
            </Link>
          </div>
          )
        }
      </div>

    </div>
  )
}
