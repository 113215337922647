
import React, {useEffect, useState} from "react";
import moment from "moment";
import en from 'moment/locale/en-gb';
import ar from 'moment/locale/ar-ma';
import {useTranslation} from "react-i18next";

const CustomAccordion = ({ title, date, children }) => {

  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion ${isOpen ? "active" : ""}`} style={{marginBottom: "20px"}} >
      <div className={`accordion-header ${isOpen ? "active" : ""}`} onClick={toggleAccordion}>
        <b>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}} >
            <p style={{padding: 0, margin: 0}} >
              {title}
              <span className={`material-symbols-outlined ${isOpen ? "active" : ""}`}> ..</span>
            </p>
          </div>
          <hr/>
        </b>
      </div>

      {isOpen && (
        <div className="accordion-content">
          <p>{children}</p>
        </div>
      )}
    </div>
  );
};

export default CustomAccordion;
